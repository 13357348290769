import {inject, Injectable} from '@angular/core';
import {BienFactory} from '@models/bien/bien.factory';
import Location from '@models/locations/location/location.model';
import {Observable} from 'rxjs';
import {ILocationApi} from '@models/locations/location/location.interfaces';
import {NgLocation} from '@legacy/app/managers/ressources';
import {map} from 'rxjs/operators';
import {LocationsApiService} from '@models/locations/locations.api.service';
import {DictionaryItemFactory} from '@models/dictionaries/dictionary/items/item/dictionary-item.factory';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {IDossierBienDiffusionEtatApi} from '@models/dossiers/biens/bien/dossier-bien.interfaces';

@Injectable({providedIn: 'root'})
export class LocationFactory {
    private _bienFactory = inject(BienFactory);
    private _dictionaryItemFactory = inject(DictionaryItemFactory);
    private _dictionaryItemService = inject(DictionaryItemService);
    private _locationsApiService = inject(LocationsApiService);

    create(locationApi: ILocationApi): Location {
        const reference = locationApi._embedded?.bien?.reference;
        const location = this.createVirgin(locationApi.uuid, locationApi.id, reference);

        location.archiveComments = locationApi.archiveComments;
        location.charges = locationApi.charges;
        location.chargesIncluses = locationApi.chargesIncluses;
        location.dateCreation = locationApi.dateCreation;
        location.depotGarantie = locationApi.depotGarantie;
        location.droitBail = locationApi.droitBail;
        location.etatLieuxChargeLocataire = locationApi.etatLieuxChargeLocataire;
        location.etatLieuxChargeProprietaire = locationApi.etatLieuxChargeProprietaire;
        location.fraisCautionnement = locationApi.fraisCautionnement;
        location.honoNegoChargeLocataire = locationApi.honoNegoChargeLocataire;
        location.honoNegoChargeProprietaire = locationApi.honoNegoChargeProprietaire;
        location.honoRedacChargeLocataire = locationApi.honoRedacChargeLocataire;
        location.honoRedacChargeProprietaire = locationApi.honoRedacChargeProprietaire;
        location.loyer = +locationApi.loyer;
        location.loyerBase = +locationApi.loyerBase;
        location.loyerEncadrement = locationApi.loyerEncadrement;
        location.loyerComplement = +locationApi.loyerComplement;
        location.loyerReferenceMajore = +locationApi.loyerReferenceMajore;
        location.loyerVersement = locationApi.loyerVersement;
        location.meuble = locationApi.meuble;
        location.montantTotalDu = locationApi.montantTotalDu;
        location.montantVersementLocataire = locationApi.montantVersementLocataire;
        location.statut = locationApi.statut;
        location.typeLoyer = this._dictionaryItemService.getByCode(Dictionary.names.LOCATION_LOYER_TYPES, locationApi.typeLoyer || Location.loyerTypes.HORS_TAXES);
        location.setInterne(locationApi.interne);
        if (locationApi._embedded) {
            if (locationApi._embedded.bien) {
                location.bien = this._bienFactory.create(locationApi._embedded.bien);
            }

            if (locationApi._embedded.periodiciteCharges) {
                location.periodiciteCharges = this._dictionaryItemFactory.create(locationApi._embedded.periodiciteCharges);
            }

            if (locationApi._embedded.periodiciteLoyer) {
                location.periodiciteLoyer = this._dictionaryItemFactory.create(locationApi._embedded.periodiciteLoyer);
            }
        }

        if (locationApi._links) {
            if (locationApi._links.contactsGroup) {
                location.linkContactsGroup = locationApi._links.contactsGroup.href;
            }

            if (locationApi._links.self) {
                location.linkSelf = locationApi._links.self.href;
            }
        }

        if (!location.periodiciteCharges) {
            location.periodiciteCharges = this._dictionaryItemService.getById(Dictionary.names.PERIODICITES, 1);
        }

        if (!location.periodiciteLoyer) {
            location.periodiciteLoyer = this._dictionaryItemService.getById(Dictionary.names.PERIODICITES, 1);
        }

        return location;
    }

    createFromDossierBienDiffusionEtat(dossierBienDiffusionEtatApi: IDossierBienDiffusionEtatApi): Location {
        const location = this.createVirgin(null!, dossierBienDiffusionEtatApi.id, dossierBienDiffusionEtatApi.reference);

        location.bien = this._bienFactory.createFromDossierBienDiffusionEtat(dossierBienDiffusionEtatApi);

        return location;
    }

    createVirgin(uuid = Location.statuts.NEW, id?: number, reference?: string): Location {
        return new Location(uuid, id, reference);
    }

    forApi(location: Location): ILocationApi {
        const locationApi = {
            archiveComments: location.archiveComments,
            bien: this._bienFactory.forApi(location.bien),
            charges: location.charges,
            chargesIncluses: location.chargesIncluses,
            depotGarantie: location.depotGarantie,
            droitBail: location.droitBail,
            etatLieuxChargeLocataire: location.etatLieuxChargeLocataire,
            etatLieuxChargeProprietaire: location.etatLieuxChargeProprietaire,
            fraisCautionnement: location.fraisCautionnement,
            honoNegoChargeLocataire: location.honoNegoChargeLocataire,
            honoNegoChargeProprietaire: location.honoNegoChargeProprietaire,
            honoRedacChargeLocataire: location.honoRedacChargeLocataire,
            honoRedacChargeProprietaire: location.honoRedacChargeProprietaire,
            loyer: location.loyer,
            loyerEncadrement: location.loyerEncadrement,
            loyerVersement: location.loyerVersement,
            meuble: location.meuble,
            montantTotalDu: location.montantTotalDu,
            montantVersementLocataire: location.montantVersementLocataire,
            periodiciteChargesId: location.periodiciteCharges?.id,
            periodiciteLoyerId: location.periodiciteLoyer?.id,
            typeLoyer: location.typeLoyer?.code,
        } as ILocationApi;

        if (location.loyerEncadrement) {
            locationApi.loyerBase = location.loyerBase;
            locationApi.loyerComplement = location.loyerComplement;
            locationApi.loyerReferenceMajore = location.loyerReferenceMajore;
        }

        return locationApi;
    }

    getByLink$(link: string): Observable<Location> {
        return this._locationsApiService.getByLink$(link).pipe(map(locationApi => this.create(locationApi)));
    }

    networkDiffuse$(location: Location): Observable<void> {
        return this._locationsApiService.networkDiffuse$(location.id.toString());
    }

    openDetailsInNewTab$(location: Location): Observable<Window> {
        return this._locationsApiService.openDetailsInNewTab$(location.id.toString());
    }

    save$(location: Location): Observable<Location> {
        return this._locationsApiService.save$(location.id?.toString() || Location.statuts.NEW, this.forApi(location)).pipe(map(locationApi => this.create(locationApi)));
    }

    ngCreate(ngLocation: NgLocation): Location {
        const reference = ngLocation.bien?.reference;
        const location = this.createVirgin(ngLocation.uuid, ngLocation.id, reference);

        location.archiveComments = ngLocation.archiveComments;
        location.chargesIncluses = ngLocation.chargesIncluses;
        location.droitBail = ngLocation.droitBail;
        location.fraisCautionnement = ngLocation.fraisCautionnement;
        location.loyerEncadrement = ngLocation.loyerEncadrement;
        location.loyerVersement = ngLocation.loyerVersement;
        location.meuble = ngLocation.meuble;
        location.statut = ngLocation.statut;
        location.setInterne(ngLocation.interne);
        if (ngLocation._links) {
            if (ngLocation._links.contactsGroup) {
                location.linkContactsGroup = ngLocation._links.contactsGroup.href;
            }

            if (ngLocation._links.self) {
                location.linkSelf = ngLocation._links.self.href;
            }
        }

        if (ngLocation.bien) {
            location.bien = this._bienFactory.ngCreate(ngLocation.bien);
        }

        if (ngLocation.charges || ngLocation.charges >= 0) {
            location.charges = parseFloat(ngLocation.charges?.toString().replace(',', '.')) || null!;
        }

        if (ngLocation.depotGarantie || ngLocation.depotGarantie >= 0) {
            location.depotGarantie = parseFloat(ngLocation.depotGarantie?.toString().replace(',', '.')) || null!;
        }

        if (ngLocation.etatLieuxChargeLocataire || ngLocation.etatLieuxChargeLocataire >= 0) {
            location.etatLieuxChargeLocataire = parseFloat(ngLocation.etatLieuxChargeLocataire?.toString().replace(',', '.')) || null!;
        }

        if (ngLocation.etatLieuxChargeProprietaire || ngLocation.etatLieuxChargeProprietaire >= 0) {
            location.etatLieuxChargeProprietaire = parseFloat(ngLocation.etatLieuxChargeProprietaire?.toString().replace(',', '.')) || null!;
        }

        if (ngLocation.honoNegoChargeProprietaire || ngLocation.honoNegoChargeProprietaire >= 0) {
            location.honoNegoChargeProprietaire = parseFloat(ngLocation.honoNegoChargeProprietaire?.toString().replace(',', '.')) || null!;
        }

        if (ngLocation.honoNegoChargeLocataire || ngLocation.honoNegoChargeLocataire >= 0) {
            location.honoNegoChargeLocataire = parseFloat(ngLocation.honoNegoChargeLocataire?.toString().replace(',', '.')) || null!;
        }

        if (ngLocation.honoRedacChargeLocataire || ngLocation.honoRedacChargeLocataire >= 0) {
            location.honoRedacChargeLocataire = parseFloat(ngLocation.honoRedacChargeLocataire?.toString().replace(',', '.')) || null!;
        }

        if (ngLocation.honoRedacChargeProprietaire || ngLocation.honoRedacChargeProprietaire >= 0) {
            location.honoRedacChargeProprietaire = parseFloat(ngLocation.honoRedacChargeProprietaire?.toString().replace(',', '.')) || null!;
        }

        if (ngLocation.loyer || ngLocation.loyer >= 0) {
            location.loyer = parseFloat(ngLocation.loyer?.toString().replace(',', '.')) || null!;
        }

        if (ngLocation.loyerBase || ngLocation.loyerBase >= 0) {
            location.loyerBase = parseFloat(ngLocation.loyerBase?.toString().replace(',', '.')) || null!;
        }

        if (ngLocation.loyerComplement || ngLocation.loyerComplement >= 0) {
            location.loyerComplement = parseFloat(ngLocation.loyerComplement?.toString().replace(',', '.')) || null!;
        }

        if (ngLocation.loyerReferenceMajore || ngLocation.loyerReferenceMajore >= 0) {
            location.loyerReferenceMajore = parseFloat(ngLocation.loyerReferenceMajore?.toString().replace(',', '.')) || null!;
        }

        if (ngLocation.montantTotalDu || ngLocation.montantTotalDu >= 0) {
            location.montantTotalDu = parseFloat(ngLocation.montantTotalDu?.toString().replace(',', '.')) || null!;
        }

        if (ngLocation.montantVersementLocataire || ngLocation.montantVersementLocataire >= 0) {
            location.montantVersementLocataire = parseFloat(ngLocation.montantVersementLocataire?.toString().replace(',', '.')) || null!;
        }

        if (ngLocation.periodiciteCharges) {
            location.periodiciteCharges = this._dictionaryItemFactory.ngCreate(ngLocation.periodiciteCharges);
        } else {
            location.periodiciteCharges = this._dictionaryItemService.getById(Dictionary.names.PERIODICITES, 1);
        }

        if (ngLocation.periodiciteLoyer) {
            location.periodiciteLoyer = this._dictionaryItemFactory.ngCreate(ngLocation.periodiciteLoyer);
        } else {
            location.periodiciteLoyer = this._dictionaryItemService.getById(Dictionary.names.PERIODICITES, 1);
        }

        if (ngLocation.typeLoyer) {
            location.typeLoyer = this._dictionaryItemFactory.ngCreate(ngLocation.typeLoyer);
        } else {
            location.typeLoyer = this._dictionaryItemService.getByCode(Dictionary.names.LOCATION_LOYER_TYPES, Location.loyerTypes.HORS_TAXES);
        }

        return location;
    }
}
