export default `
<form name="$ctrl.editForm" ng-submit="$ctrl.launch()" novalidate class="procedure-edit">
  <div class="modal-header">
    <button type="button" class="btn btn-default pull-right" ng-click="$ctrl.dismiss()"
            title="Fermer{{$ctrl.procedure.isNewOrDraft() ? ' sans enregistrer la procédure': ''}}">
      <fa-icon [icon]="'times'"></fa-icon>
    </button>

    <h3 class="modal-title">
      <fa-icon [icon]="'feather-alt'"></fa-icon>

      Procédure de signature {{$ctrl.procedure.providerLabel}}
      {{$ctrl.documentProcedure.id > 0 ? ('du ' + $ctrl.documentProcedure.titre) : 'd\\'un document supprimé'}}
    </h3>
  </div>

  <div class="modal-body">
    <div ng-if="$ctrl.procedure.isNewOrDraft()" class="margin-bottom-15">
      <div ng-if="$ctrl.procedure.isNew() && $ctrl.procedureProviders.length > 1">
        <div class="tw-flex tw-justify-center">
          <div class="radio-inline" ng-repeat="procedureProviders in $ctrl.procedureProviders">
            <div class="radio clip-radio radio-primary">
              <input type="radio" id="provider-{{procedureProviders.code}}" ng-model="$ctrl.procedure.provider"
                     name="provider" ng-value="procedureProviders.code">

              <label for="provider-{{procedureProviders.code}}">Signature {{procedureProviders.label}}</label>
            </div>
          </div>
        </div>

        <hr class="tw-mt-0">
      </div>

      <div class="row tw-relative">
        <div class="col-xs-8 col-sm-7 col-md-5">
          <esk-select-participant on-select="$ctrl.addSignataire" options="$ctrl.options.participants">
          </esk-select-participant>

          <button class="tw-absolute tw-right-[15px] tw-btn-tertiary-info tw-mt-px tw-p-0 tw-text-xs tw-font-normal"
                  type="button" ng-click="$ctrl.addMe()" ng-if="!$ctrl.amSignataire">
            M'ajouter en tant que signataire
          </button>
        </div>

        <div class="col-xs-3">
          <button class="btn btn-o btn-primary" type="button" ng-click="$ctrl.addSignataire()">
            <fa-icon [icon]="'plus'"></fa-icon>
          </button>
        </div>
      </div>
    </div>

    <div ng-if="!$ctrl.procedure.isNewOrDraft()" class="margin-bottom-15 procedure-status">
      <h4 class="margin-right-10 margin-bottom-0 text-bold">{{$ctrl.procedure.labelStatut | uppercase}}</h4>

      <div class="text-muted">
        {{$ctrl.procedure.updatedAt | date:'longDate'}} à {{$ctrl.procedure.updatedAt | date:'shortTime'}}
      </div>
    </div>

    <div ng-if="$ctrl.procedure.cSignataires.total > 0" class="table-responsive">
      <table class="table margin-bottom-30 padding-bottom-30">
        <tr ng-repeat="procedureSignataire in $ctrl.procedure.cSignataires.results">
          <td class="signataire-prenom">
            <div ng-if="$ctrl.procedure.isEditable() && procedureSignataire.isEditable()"
                 ng-class="{'has-error': !procedureSignataire.prenom}">
              <input type="text" ng-model="procedureSignataire.prenom" class="form-control" placeholder="Prénom"
                     required maxlength="140"
                     pattern="[a-zA-Z0-9àáâãäåæçèéêëìíîïñòóôõöùúûüýÿÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÑÒÓÔÕÖÙÚÛÜÝ ()\\-'’ß]{1,140}">

              <div ng-if="$ctrl.editForm.$submitted && !procedureSignataire.prenom" class="error text-small tw-block">
                Champ requis
              </div>
            </div>

            <div ng-if="!$ctrl.procedure.isEditable() || !procedureSignataire.isEditable()" class="text-large">
              {{procedureSignataire.prenom}}
            </div>
          </td>

          <td class="signataire-nom">
            <div ng-if="$ctrl.procedure.isEditable() && procedureSignataire.isEditable()"
                 ng-class="{'has-error': !procedureSignataire.nom}">
              <input type="text" ng-model="procedureSignataire.nom" class="form-control" placeholder="Nom" required
                     maxlength="140"
                     pattern="[a-zA-Z0-9àáâãäåæçèéêëìíîïñòóôõöùúûüýÿÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÑÒÓÔÕÖÙÚÛÜÝ ()\\-'’ß]{1,140}">

              <div ng-if="$ctrl.editForm.$submitted && !procedureSignataire.nom" class="error text-small tw-block">
                Champ requis
              </div>
            </div>

            <div ng-if="!$ctrl.procedure.isEditable() || !procedureSignataire.isEditable()" class="text-large">
              {{procedureSignataire.nom}}
            </div>
          </td>

          <td ng-if="!$ctrl.procedure.isPresentiel()" class="signataire-email">
            <div ng-if="$ctrl.procedure.isEditable() && procedureSignataire.isEditable()"
                 ng-class="{'has-error': !procedureSignataire.email}">
              <input type="email" ng-model="procedureSignataire.email" class="form-control"
                     placeholder="exemple@noty.com" required>

              <div ng-if="$ctrl.editForm.$submitted && !procedureSignataire.email" class="error text-small tw-block">
                Champ requis
              </div>
            </div>

            <div ng-if="!$ctrl.procedure.isEditable() || !procedureSignataire.isEditable()">
              {{procedureSignataire.email}}
            </div>
          </td>

          <td ng-if="!$ctrl.procedure.isPresentiel()" class="signataire-telephone">
            <div ng-if="$ctrl.procedure.isEditable() && procedureSignataire.isEditable()"
                 ng-class="{'has-error': !$ctrl.ng2PhoneNumberService.isValid(procedureSignataire.telephone)}">
              <input type="tel" ng-model="procedureSignataire.telephone" class="form-control" required
                     placeholder="06 06 06 06 06">

              <div ng-if="$ctrl.editForm.$submitted && !procedureSignataire.telephone" class="error text-small tw-block">
                Champ requis
              </div>
            </div>

            <div ng-if="!$ctrl.procedure.isEditable() || !procedureSignataire.isEditable()">
              {{procedureSignataire.telephone}}
            </div>
          </td>

          <td class="signataire-status text-large">
            <div ng-if="!$ctrl.procedure.isNewOrDraft() || !procedureSignataire.isEditable()">
              {{procedureSignataire.labelStatut}}

              <div ng-if="procedureSignataire.isClosed()" class="status-date text-muted">
                {{(procedureSignataire.finishedAt || procedureSignataire.refusedAt) | date:'longDate'}} à
                {{(procedureSignataire.finishedAt || procedureSignataire.refusedAt) | date: 'shortTime'}}
              </div>
            </div>
          </td>

          <td class="signataire-actions">
            <div ng-if="($ctrl.procedure.isNewOrDraft() && procedureSignataire.isEditable()) || ($ctrl.procedure.isEditable() && procedureSignataire.isEditable() && !!procedureSignataire.linkInterfaceSignature)"
                 class="btn-group" role="group">
              <button type="button" class="btn dropdown-toggle btn-o" data-toggle="dropdown">
                <fa-icon [icon]="'ellipsis-v'"></fa-icon>
              </button>

              <div class="dropdown-menu pull-right">
                <ul class="list-unstyled margin-bottom-0">
                  <li ng-if="$ctrl.procedure.isNewOrDraft() && procedureSignataire.isEditable()">
                    <button type="button" class="tw-dropdown-link"
                            ng-click="$ctrl.removeSignataire(procedureSignataire)">
                      <fa-icon [icon]="'trash-alt'"></fa-icon><span>Supprimer</span>
                    </button>
                  </li>

                  <li ng-if="($ctrl.procedure.isNewOrDraft() && procedureSignataire.isEditable()) && ($ctrl.procedure.isEditable() && procedureSignataire.isEditable() && !!procedureSignataire.linkInterfaceSignature)"
                      class="divider"></li>

                  <li ng-if="$ctrl.procedure.isEditable() && procedureSignataire.isEditable() && !!procedureSignataire.linkInterfaceSignature">
                    <button type="button" class="tw-dropdown-link"
                            ng-click="$ctrl.copyLinkInterfaceSignature(procedureSignataire)">
                      <fa-icon [icon]="['far', 'copy']"></fa-icon><span>Copier le lien de signature</span>
                    </button>
                  </li>

                  <li ng-if="$ctrl.procedure.isEditable() && procedureSignataire.isEditable() && !!procedureSignataire.linkInterfaceSignature">
                    <button type="button" class="tw-dropdown-link"
                            ng-click="$ctrl.sendLinkInterfaceSignature(procedureSignataire)">
                      <fa-icon [icon]="['far', 'envelope']"></fa-icon><span>Envoyer le lien de signature</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>

    <div ng-if="$ctrl.procedure.cSignataires.total <= 0" class="margin-top-30 margin-bottom-30 tw-text-center">
      Au moins un signataire est requis pour lancer une procédure de signature électronique de votre document.
    </div>
  </div>

  <div class="modal-footer">
    <h4 class="margin-top-5 hidden-xs">
      {{$ctrl.procedure.cSignataires.total + ' Signataire' + ($ctrl.procedure.cSignataires.total > 1 ? 's' : '')}}
    </h4>

    <div ng-if="$ctrl.options.loader" class="text-extra-large">
      <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> En cours
    </div>

    <div ng-if="!$ctrl.options.loader">
      <button ng-if="!$ctrl.procedure.isNew() && !$ctrl.procedure.isClosed()" class="btn btn-danger btn-o" type="button"
              ng-click="$ctrl.cancel()">
        <fa-icon [icon]="'times'"></fa-icon> Annuler la procédure
      </button>

      <button ng-if="$ctrl.procedure.isNewOrDraft()" class="btn btn-primary" type="submit">
        <fa-icon [icon]="'feather-alt'"></fa-icon> Lancer la procédure
      </button>

      <button ng-if="!$ctrl.procedure.isNewOrDraft() && $ctrl.procedure.isEditable()" class="btn btn-primary" type="button"
              ng-click="$ctrl.saveSignataires()">
        <fa-icon [icon]="'check'"></fa-icon> Enregistrer
      </button>
    </div>
  </div>
</form>
`;
